@import './Button.css';
@import './theme.css';

@layer plone-components.base {
  .react-aria-Tooltip {
    max-width: 150px;
    padding: 2px 8px;
    border-radius: 4px;
    background: var(--highlight-background);
    box-shadow: 0 8px 20px rgba(0 0 0 / 0.1);
    color: var(--highlight-foreground);
    forced-color-adjust: none;
    outline: none;
    /* fixes FF gap */
    transform: translate3d(0, 0, 0);

    &[data-placement='top'] {
      margin-bottom: 8px;
      --origin: translateY(4px);
    }

    &[data-placement='bottom'] {
      margin-top: 8px;
      --origin: translateY(-4px);
      & .react-aria-OverlayArrow svg {
        transform: rotate(180deg);
      }
    }

    &[data-placement='right'] {
      margin-left: 8px;
      --origin: translateX(-4px);
      & .react-aria-OverlayArrow svg {
        transform: rotate(90deg);
      }
    }

    &[data-placement='left'] {
      margin-right: 8px;
      --origin: translateX(4px);
      & .react-aria-OverlayArrow svg {
        transform: rotate(-90deg);
      }
    }

    & .react-aria-OverlayArrow svg {
      display: block;
      fill: var(--highlight-background);
    }

    &[data-entering] {
      animation: slide 200ms;
    }

    &[data-exiting] {
      animation: slide 200ms reverse ease-in;
    }
  }

  @keyframes slide {
    from {
      opacity: 0;
      transform: var(--origin);
    }

    to {
      opacity: 1;
      transform: translateY(0);
    }
  }
}
