@import './ListBox.css';
@import './Popover.css';
@import './Button.css';
@import './Form.css';
@import './theme.css';

@layer plone-components.base {
  .react-aria-Select {
    color: var(--text-color);

    .react-aria-Button {
      display: flex;
      max-width: 250px;
      align-items: center;
      padding: 0.286rem 0.286rem 0.286rem 0.571rem;
      border-radius: 6px;
      box-shadow: 0 1px 2px rgba(0 0 0 / 0.1);
      font-size: 1.072rem;

      &[data-focus-visible] {
        outline: 2px solid var(--focus-ring-color);
        outline-offset: -1px;
      }
    }

    &[data-invalid] {
      .react-aria-Button {
        border: 1px solid var(--color-invalid);
      }

      .react-aria-Label {
        color: var(--color-invalid);
      }
    }

    &[data-disabled] {
      .react-aria-Label {
        color: var(--basic-400);
      }

      [slot='description'] {
        color: var(--basic-400);
      }
    }

    .react-aria-SelectValue {
      &[data-placeholder] {
        color: var(--text-color-placeholder);
        font-style: italic;
      }
    }

    span[aria-hidden] {
      width: 1.5rem;
      padding: 1px;
      border-radius: 4px;
      margin-left: 1rem;
      background: var(--highlight-background);
      color: var(--highlight-foreground);
      font-size: 0.857rem;
      forced-color-adjust: none;
      line-height: 1.375rem;
    }

    &[data-required] .react-aria-Label::after {
      /* https://yoksel.github.io/url-encoder/ */
      content: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' viewBox='0 0 24 24'%3E%3Cpath fill='hsl(7, 90%25, 59%25)' d='M12 18a6 6 0 100-12 6 6 0 000 12z'/%3E%3C/svg%3E");
    }
  }

  .react-aria-Popover[data-trigger='Select'] {
    min-width: var(--trigger-width);

    .react-aria-ListBox {
      display: block;
      width: unset;
      min-height: unset;
      max-height: inherit;
      border: none;

      .react-aria-Header {
        padding-left: 1.571rem;
      }
    }

    .react-aria-ListBoxItem {
      padding: 0.286rem 0.571rem 0.286rem 1.571rem;

      &[data-focus-visible] {
        outline: none;
      }

      &[data-selected] {
        background: unset;
        color: var(--text-color);
        font-weight: 600;

        &::before {
          position: absolute;
          top: 4px;
          left: 4px;
          alt: ' ';
          content: '✓';
          content: '✓' / '';
        }
      }

      &[data-focused],
      &[data-pressed] {
        background: var(--highlight-background);
        color: var(--highlight-foreground);
      }
    }
  }

  .react-aria-ListBoxItem[href] {
    cursor: pointer;
    text-decoration: none;
  }

  .react-aria-Select {
    .react-aria-SelectValue {
      [slot='description'] {
        display: none;
      }
    }

    .react-aria-Button {
      &[data-disabled] {
        border-color: var(--border-color-disabled);
        color: var(--text-color-disabled);
        span[aria-hidden] {
          background: var(--border-color-disabled);
          color: var(--text-color-disabled);
        }

        .react-aria-SelectValue {
          &[data-placeholder] {
            color: var(--text-color-disabled);
          }
        }
      }
    }
  }

  @media (forced-colors: active) {
    .react-aria-Select {
      .react-aria-Button {
        &[data-disabled] span[aria-hidden] {
          background: 0 0;
        }
      }
    }
  }

  .react-aria-Select {
    .react-aria-FieldError {
      color: var(--color-invalid);
      font-size: 12px;
    }

    [slot='description'] {
      /* For now, we want the error in under the description */
      display: block;
      padding-top: 3px;
      font-size: 12px;
    }
  }
}
