@import './Button.css';
@import './theme.css';

@layer plone-components.base {
  .react-aria-Calendar {
    width: fit-content;
    max-width: 100%;
    color: var(--text-color);

    header {
      display: flex;
      align-items: center;
      margin: 0 4px 0.5rem 4px;

      .react-aria-Heading {
        flex: 1;
        margin: 0;
        font-size: 1.375rem;
        text-align: center;
      }
    }

    .react-aria-Button {
      width: 2rem;
      height: 2rem;
      padding: 0;
    }

    .react-aria-CalendarCell {
      width: 2rem;
      border-radius: 6px;
      margin: 1px;
      cursor: default;
      forced-color-adjust: none;
      line-height: 2rem;
      outline: none;
      text-align: center;

      &[data-outside-month] {
        display: none;
      }

      &[data-pressed] {
        background: var(--gray-100);
      }

      &[data-focus-visible] {
        outline: 2px solid var(--focus-ring-color);
        outline-offset: 2px;
      }

      &[data-selected] {
        background: var(--highlight-background);
        color: var(--highlight-foreground);
      }
    }

    .react-aria-CalendarCell {
      &[data-disabled] {
        color: var(--text-color-disabled);
      }
    }

    .react-aria-CalendarCell {
      &[data-unavailable] {
        color: var(--color-invalid);
        text-decoration: line-through;
      }
    }

    .react-aria-CalendarCell {
      &[data-invalid] {
        background: var(--color-invalid);
        color: var(--highlight-foreground);
      }
    }

    [slot='errorMessage'] {
      color: var(--color-invalid);
      font-size: 12px;
    }
  }
}
