@import './theme.css';

@layer plone-components.base {
  .react-aria-Meter {
    --fill-color: forestgreen;

    display: grid;
    width: 250px;
    color: var(--text-color);
    gap: 4px;
    grid-template-areas:
      'label value'
      'bar bar';
    grid-template-columns: 1fr auto;

    .value {
      grid-area: value;
    }

    .bar {
      overflow: hidden;
      height: 10px;
      border-radius: 5px;
      box-shadow: inset 0px 0px 0px 1px var(--border-color);
      forced-color-adjust: none;
      grid-area: bar;
    }

    .fill {
      height: 100%;
      background: var(--fill-color);
    }
  }

  @media (forced-colors: active) {
    .react-aria-Meter {
      --fill-color: Highlight;
    }
  }
}
