@import './TextField.css';
@import './Button.css';
@import './theme.css';

@layer plone-components.base {
  .react-aria-Form {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 8px;
  }

  .react-aria-Form [role='alert'] {
    max-width: 250px;
    padding: 12px;
    border: 2px solid var(--color-invalid);
    border-radius: 6px;
    background: var(--overlay-background);
    outline: none;

    &:focus-visible {
      outline: 2px solid var(--focus-ring-color);
      outline-offset: 2px;
    }

    h3 {
      margin-top: 0;
    }

    p {
      margin-bottom: 0;
    }
  }
}
