@import './Button.css';
@import './TextField.css';
@import './theme.css';

@layer plone-components.base {
  .react-aria-ModalOverlay {
    position: fixed;
    z-index: 100;
    top: 0;
    left: 0;
    display: flex;
    width: 100vw;
    height: var(--visual-viewport-height);
    align-items: center;
    justify-content: center;
    background: rgba(0 0 0 / 0.5);

    &[data-entering] {
      animation: modal-fade 200ms;
    }

    &[data-exiting] {
      animation: modal-fade 150ms reverse ease-in;
    }
  }

  .react-aria-Modal {
    max-width: 300px;
    border: 1px solid var(--border-color);
    border-radius: 6px;
    background: var(--overlay-background);
    box-shadow: 0 8px 20px rgba(0 0 0 / 0.1);
    color: var(--text-color);
    outline: none;

    &[data-entering] {
      animation: modal-zoom 300ms cubic-bezier(0.175, 0.885, 0.32, 1.275);
    }

    .react-aria-TextField {
      margin-bottom: 8px;
    }
  }

  @keyframes modal-fade {
    from {
      opacity: 0;
    }

    to {
      opacity: 1;
    }
  }

  @keyframes modal-zoom {
    from {
      transform: scale(0.8);
    }

    to {
      transform: scale(1);
    }
  }

  @keyframes mymodal-blur {
    from {
      backdrop-filter: blur(0);
      background: rgba(45 0 0 / 0);
    }

    to {
      backdrop-filter: blur(10px);
      background: rgba(45 0 0 / 0.3);
    }
  }

  @keyframes mymodal-slide {
    from {
      transform: translateX(100%);
    }

    to {
      transform: translateX(0);
    }
  }
}
