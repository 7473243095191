@import './theme.css';

@layer plone-components.base {
  .react-aria-ToggleButton {
    padding: 8px 8px;
    border: 1px solid var(--border-color);
    border-radius: 6px;
    margin: 0;
    appearance: none;
    background: var(--button-background);
    color: var(--text-color);
    font-size: 1rem;
    forced-color-adjust: none;
    outline: none;
    text-align: center;
    vertical-align: middle;

    &[data-pressed] {
      border-color: var(--border-color-pressed);
      background: var(--button-background-pressed);
      box-shadow: inset 0 1px 2px rgb(0 0 0 / 0.1);
    }

    &[data-selected] {
      border-color: var(--highlight-background);
      background: var(--highlight-background);
      color: var(--highlight-foreground);

      &[data-pressed] {
        border-color: var(--highlight-background-pressed);
        background: var(--highlight-background-pressed);
      }
    }

    &[data-focus-visible] {
      outline: 2px solid var(--focus-ring-color);
      outline-offset: 2px;
    }

    &[data-disabled] {
      border-color: var(--border-color-disabled);
      background: var(--button-background);
      color: var(--text-color-disabled);
    }

    .icon:not(:last-child) {
      margin-right: 6px;
    }

    &:has(.icon) {
      display: inline-flex;
      align-items: center;
      line-height: 0;
    }
  }
}
