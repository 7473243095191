@import './Button.css';
@import './Form.css';
@import './theme.css';

@layer plone-components.base {
  .react-aria-RadioGroup {
    display: flex;
    flex-direction: column;
    color: var(--text-color);
    gap: 8px;
  }

  .react-aria-Radio {
    display: flex;
    align-items: center;
    color: var(--text-color);
    font-size: 1.143rem;
    forced-color-adjust: none;
    gap: 0.571rem;

    &:before {
      display: block;
      width: 1.286rem;
      height: 1.286rem;
      box-sizing: border-box;
      border: 0.143rem solid var(--border-color);
      border-radius: 1.286rem;
      background: var(--field-background);
      content: '';
      transition: all 200ms;
    }

    &[data-pressed]:before {
      border-color: var(--border-color-pressed);
    }

    &[data-selected] {
      &:before {
        border-width: 0.429rem;
        border-color: var(--highlight-background);
      }

      &[data-pressed]:before {
        border-color: var(--highlight-background-pressed);
      }
    }

    &[data-focus-visible]:before {
      outline: 2px solid var(--focus-ring-color);
      outline-offset: 2px;
    }

    &[data-invalid] {
      &:before {
        border-color: var(--color-invalid);
      }

      &[data-pressed]:before {
        border-color: var(--color-pressed-invalid);
      }
    }
  }

  .react-aria-RadioGroup {
    .react-aria-FieldError {
      color: var(--color-invalid);
      font-size: 12px;
    }

    [slot='description'] {
      font-size: 12px;
    }

    &[data-orientation='horizontal'] {
      flex-direction: row;
      align-items: center;
    }
  }

  .react-aria-Radio {
    &[data-disabled] {
      color: var(--text-color-disabled);

      &:before {
        border-color: var(--border-color-disabled);
      }
    }
  }
}
