@import './Button.css';
@import './Popover.css';
@import './Dialog.css';
@import './DateField.css';
@import './Calendar.css';
@import './Form.css';
@import './theme.css';

@layer plone-components.base {
  .react-aria-DatePicker {
    color: var(--text-color);

    .react-aria-Group {
      display: flex;
      width: fit-content;
      align-items: center;
    }

    .react-aria-Button {
      width: 1.429rem;
      height: 1.429rem;
      box-sizing: content-box;
      padding: 0;
      border: 2px solid var(--field-background);
      border: none;
      border-radius: 4px;
      margin-left: -1.929rem;
      background: var(--highlight-background);
      color: var(--highlight-foreground);
      font-size: 0.857rem;
      forced-color-adjust: none;

      &[data-pressed] {
        background: var(--highlight-background);
        box-shadow: none;
      }

      &[data-focus-visible] {
        outline: 2px solid var(--focus-ring-color);
        outline-offset: 2px;
      }
    }

    .react-aria-DateInput {
      padding: 4px 2.5rem 4px 8px;
    }
  }

  .react-aria-Popover[data-trigger='DatePicker'] {
    max-width: unset;
  }

  .react-aria-DatePicker {
    &[data-invalid] {
      .react-aria-DateInput:after {
        flex: 1;
        alt: ' ';
        content: '🚫' / '';
        content: '🚫';
        text-align: end;
      }
    }

    .react-aria-FieldError {
      color: var(--color-invalid);
      font-size: 12px;
    }

    [slot='description'] {
      font-size: 12px;
    }
  }
}
