@import './Checkbox.css';
@import './Button.css';
@import './ToggleButton.css';
@import './Menu.css';
@import './theme.css';

@layer plone-components.base {
  .blocks-toolbar {
    display: flex;
    flex-wrap: wrap;

    padding: 6px;
    border-radius: 6px;
    box-shadow:
      0px 6px 12px 0px rgba(2, 19, 34, 0.06),
      0px 9px 18px 0px rgba(2, 19, 34, 0.18);
    gap: 5px;

    &[data-orientation='horizontal'] {
      flex-direction: row;
    }
    .react-aria-Group {
      display: contents;
    }
  }

  .react-aria-Separator {
    align-self: stretch;
    background-color: var(--border-color);

    &[aria-orientation='vertical'] {
      width: 1px;
      margin: 0px 10px;
    }
  }

  .blocks-toolbar {
    width: fit-content;

    &[data-orientation='vertical'] {
      flex-direction: column;
      align-items: flex-start;
    }
  }

  .react-aria-Separator {
    &:not([aria-orientation='vertical']) {
      width: 100%;
      height: 1px;
      border: none;
      margin: 10px 0;
    }
  }
}
