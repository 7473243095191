@import './ColorSlider.css';

@layer plone-components.base {
  .react-aria-ColorSwatch {
    width: 32px;
    height: 32px;
    border-radius: 4px;
    box-shadow: inset 0 0 0 1px rgba(0, 0, 0, 0.1);
  }
}
